import { LocalizationService } from '@abp/ng.core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EXPERIMENT_NAME_AND_URL_CONTENT_REGEX } from '@app/utils';
import { ValidationErrorComponent } from '@volo/abp.ng.theme.lepton';

@Component({
  selector: 'sai-validation-error',
  template: `
    <div>
      <span class="field-validation-error text-danger">
        <span>{{ checkErrorMessage(abpErrors) | abpLocalization }}</span>
      </span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent extends ValidationErrorComponent {
  constructor(private localizationService: LocalizationService) {
    super();
  }

  checkErrorMessage(abpErrors) {
    for (const error of abpErrors) {
      if (error.key === 'pattern') {
        if (error.params.requiredPattern === EXPERIMENT_NAME_AND_URL_CONTENT_REGEX) {
          return this.localizationService.instant(
            '::ExperimentNameAndUrlContentFieldPatternIsInvalid'
          );
        } else {
          return this.localizationService.instant('::ThisFieldPatternIsInvalid');
        }
      } else if (error.key === 'required') {
        return error.message;
      } else if (error.key === 'max') {
        return this.localizationService.instant(
          { key: '::SelectedInputMaxValue', defaultValue: error.params.max },
          error.interpoliteParams[1],
          ''
        );
      } else if (error.key === 'min') {
        return this.localizationService.instant(
          { key: '::SelectedInputMinValue', defaultValue: error.params.min },
          error.interpoliteParams[0],
          ''
        );
      } else {
        return error.message;
      }
    }
  }
}
